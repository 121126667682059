<template>
  <div
    class="categoryLabel"
    v-bind:style="cssProps"
  >
    {{ category.name }}
  </div>
</template>

<script>
import getCategoryTheme from '@/utils/getCategoryTheme';

export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cssProps() {
      const theme = getCategoryTheme(this.category.slug);

      return {
        'background-color': theme.programCardLabelColor,
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.categoryLabel {
  @include note--bold;
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 0.75rem;
  text-transform: uppercase;
  color: $color-white;
}
</style>